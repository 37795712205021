<template>
	<article class="new-question">
		<div class="site-wrapper rythm-v-l">
			<header>
				<h1>Nouvelle question</h1>
			</header>

			<section class="section--new-question rythm-v">
				<header>
					<h2>Étape 2 – Détails</h2>
				</header>

				<div class="card shadow">
					<form @submit.prevent="updateQuestionData()">
						<section class="rythm-v">
							<header>
								<h3>Détails de la question</h3>
							</header>

							<div class="input-group required">
								<label for="questionTitle">Titre de la question :</label>
								<input type="text"
											 name="questionTitle"
											 id="questionTitle"
											 v-model="details.questionTitle"
											 required />
							</div>

							<div class="input-group required">
								<label class="required"
											 for="questionDetails">Question détaillée :</label>
								<textarea name="questionDetails"
													id="questionDetails"
													cols="30"
													rows="5"
													v-model="details.questionDetails"
													required></textarea>
							</div>
						</section>

						<section class="rythm-v">
							<p class="text-center"
								 style="width: 51rem;max-width: 100%;margin: 0 auto;">
								En cas de suspicion d'évènement indésirable, merci de le signaler sur <a href="https://signalement.social-sante.gouv.fr/"
									 target="_blank">https://signalement.social-sante.gouv.fr/</a> (Art. R5121-161 du Code de la Santé Publique) et au service pharmacovigilance du laboratoire du produit concerné.
							</p>

							<div class="input-group--inline-controls">
								<button class="button--primary"
												type="submit"
												:disabled="isSubmitDisabled">
									<span class="caption">Enregistrer</span>
									<svg class="icon"
											 xmlns="http://www.w3.org/2000/svg"
											 width="16"
											 height="16"
											 fill="none">
							                    <path
							                      fill="currentColor"
							                      fill-rule="evenodd"
							                      d="M8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893C6.90237 0.683418 6.90237 1.31658 7.29289 1.70711L12.5858 7H1C0.447715 7 0 7.44772 0 8C0 8.55229 0.447715 9 1 9H12.5858L7.29289 14.2929C6.90237 14.6834 6.90237 15.3166 7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071L15.7071 8.70711C16.0976 8.31658 16.0976 7.68342 15.7071 7.29289L8.70711 0.292893Z"
							                      clip-rule="evenodd"
							                    />
							                  </svg>
								</button>
							</div>
						</section>
					</form>
				</div>
			</section>
		</div>
	</article>
</template>

<style lang="scss" scoped>
@import "src/scss/question.scss";
</style>

<script>
import buttonResetQuestion from "@/components/buttonResetQuestion";
import { EventBus } from "../event-bus.js";

export default {
	name: "QuestionEditDetails",

	components: {
		buttonResetQuestion,
	},

	props: ["questionID"],

	data: function () {
		return {
			isDraft: false,
			isSubmitDisabled: false,

			details: {
				questionTitle: "",
				questionDetails: "",
				patientType: "",
			},
		};
	},

	computed: {
		// Current user data
		user: function () {
			return this.$store.state.userData;
		},
	},

	methods: {
		// Update the draft then insert a new question in database
		updateQuestionData: function () {
			let payload = {
				questionID: this.questionID,
				dataFragment: { details: this.details },
			};

			// disable submit button
			this.isSubmitDisabled = true;

			this.$store
				.dispatch("SAVE_QUESTION_DRAFT", payload)
				.then(() => {
					// Now it's time to insert some stuff in database
					// NOTE: Afterwards the draft will be deleted
					this.$store
						.dispatch("SAVE_QUESTION")
						.then((questionID) => {
							// EventBus.$emit(
							//   "trackEvent",
							//   "Nouvelle question",
							//   `Question ${questionID} ajoutée par userID ${this.user.id}`
							// );
							this.$router.push(`/edit-question-documents/${questionID}`);
						})
						.catch((error) => {
							// EventBus.$emit("trackEvent", "Nouvelle question", `Erreur enregistrement par userID ${this.user.id}`);
							console.error("SAVE_QUESTION error");
							this.isSubmitDisabled = false;
						});
				})
				.catch((error) => {
					console.error("SAVE_QUESTION_DRAFT error");
					this.isSubmitDisabled = false;
				});
		},
	},

	mounted() {
		// Go back if no data
		if (!this.$store.state.questionDraft) return this.$router.push(`/edit-question-themes/0`);

		// Check if there is a local draft
		if (parseInt(this.questionID)) {
			let questionData = this.$store.state.questionDraft;
			if (null !== questionData && questionData.details) {
				this.details = questionData.details;
				this.isDraft = true;
			}
		}
		// TODO: Else, with a questionID, fetch questionData from the API
	},

	// Navigation gards
	beforeRouteLeave(to, from, next) {
		// Pass questionID if the user goes back (to question themes)
		if (to.path === "/edit-question-themes/0" && parseInt(this.questionID) !== 0) {
			return (window.location = `/edit-question-themes/${this.questionID}`);
		}
		// TODO: confirm leave?
		next();
	},
};
</script>
